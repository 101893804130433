import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";

import axios from 'axios'
import labbolUI from 'labbol-ui';
import ElementUI from 'element-ui';
// 引入echarts
import "echarts";
import ECharts from "vue-echarts";
import Vant from 'vant';
import 'vant/lib/index.css';
import 'element-ui/lib/theme-chalk/index.css'
import 'element-ui/lib/theme-chalk/index.css';
import 'labbol-ui/labbol-ui.css';
import './assets/less/common.less';
import dayjs from "dayjs";
// import 'echarts/map/js/china'
import * as echarts from 'echarts'
import api from './api/api'
import storage from './assets/js/storage'
import VueClipBoard from 'vue-clipboard2'
import VueCropper from 'vue-cropper';
// 加载全局图标样式
import './styles/icon.less'
// 引入全局签名加密法
import JsEncrypt from 'jsencrypt'
// 加载更多
import VueLoadmore from 'vuejs-loadmore';


// import './utils/mathJax';
/**
 * 配置全局的加密方法
 * @param obj 需要加密的字符串
 */
Vue.prototype.$encruption = function (obj) {
  let encrypt = new JsEncrypt()
  encrypt.setPublicKey('MIIBIjANBgkqhkiG9w0BAQEFAAOCAQ8AMIIBCgKCAQEAyVL0rI63HCHBULabtMHH4mOMcWTa6uWERy8pnlEEuLO3OfmqequV1e+k30aSJtl/mPgbA2l92uiHKPKj5jeSHYKmIue22f7q7iTXLeQ7S7bTLyosKUJZ42/W2JivOpYN4jKjTPcNMgOfaJqZ9mhUtafbnGjn7p62+tZA7QXIv4NMdjH2482A7TDuVP+etveTs8RnJvve42xuzLP1UZEtZGBtBT3rDguc84jqbf7lNHT/4r7YPZP83qSF97qzjDHlDx3sGARM1BOtLpHo7shjIMu72f4sbYTSPhrcfa14Bu+uQJlVmxDqS2CE69IEtWk2RfxAvUCRnqi4cevomSRztwIDAQAB')  // 放置自己的公钥
  return encrypt.encrypt(obj)
}

//markdown编辑器
// import VMdEditor from '@kangc/v-md-editor/lib/codemirror-editor';
import VMdEditor from '@kangc/v-md-editor';
// import '@kangc/v-md-editor/lib/style/codemirror-editor.css';
import '@kangc/v-md-editor/lib/style/base-editor.css';
import githubTheme from '@kangc/v-md-editor/lib/theme/github.js';
import '@kangc/v-md-editor/lib/theme/style/github.css';

// highlightjs
import hljs from 'highlight.js';

// codemirror 编辑器的相关资源
import Codemirror from 'codemirror';
// mode
import 'codemirror/mode/markdown/markdown';
import 'codemirror/mode/javascript/javascript';
import 'codemirror/mode/css/css';
import 'codemirror/mode/htmlmixed/htmlmixed';
import 'codemirror/mode/vue/vue';
// edit
import 'codemirror/addon/edit/closebrackets';
import 'codemirror/addon/edit/closetag';
import 'codemirror/addon/edit/matchbrackets';
// placeholder
import 'codemirror/addon/display/placeholder';
// active-line
import 'codemirror/addon/selection/active-line';
// scrollbar
import 'codemirror/addon/scroll/simplescrollbars';
import 'codemirror/addon/scroll/simplescrollbars.css';
// style
import 'codemirror/lib/codemirror.css';
import 'labbol-ui/labbol-ui.css'

VMdEditor.Codemirror = Codemirror;
// console.log('Codemirror', Codemirror);

//tinymce 富文本编辑器
import tinymce from 'tinymce/tinymce' //tinymce默认hidden，不引入不显示
// import Editor from '@tinymce/tinymce-vue'//编辑器引入
import 'tinymce/themes/silver/theme'//编辑器主题
import 'tinymce/plugins/link'  //超链接
import 'tinymce/plugins/paste'  //粘贴过滤
import 'tinymce/plugins/preview'  //预览
import 'tinymce/plugins/image'  //图片上传
import 'tinymce/plugins/emoticons'  //表情
import 'tinymce/plugins/emoticons/js/emojis'  //表情

import $choiceCatogry from "./components/ChoiceCatogry/index.js";
VMdEditor.use(githubTheme, {
  Hljs: hljs,
});
// 注册echarts
Vue.component('ECharts',ECharts);

import $login from './components/Header/index.js';
Vue.use($choiceCatogry)
Vue.use($login);
Vue.use(VueLoadmore);
Vue.use(VMdEditor);
// import './utils/load';
Vue.use(VueCropper);
Vue.use(Vant);
Vue.use(ElementUI);
Vue.use(labbolUI);
Vue.use(VueClipBoard);
Vue.prototype.$axios = axios;
Vue.prototype.api = api;
Vue.prototype.storage = storage;
Vue.prototype.$echarts = echarts;
Vue.config.productionTip = false;
Vue.prototype.dayjs = dayjs;

Vue.prototype.$EventBus = new Vue();

new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount("#app");
