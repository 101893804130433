<template>
    <div id="choicePersonnel">
        <el-dialog class="choiceCatogryDialog" :title="title" :visible.sync="visible" v-dialogDrag :modal-append-to-body="false" @close="handleClose">
            <el-radio-group v-model="category">
                  <el-radio  v-for="item in categoryList" :label="item.label" :key="item.label">{{ item.value }}</el-radio>
            </el-radio-group>

            <div class="buttons">
                <el-button  type="primary" style="float: right; margin-top: 16px" size="small" @click="handleSubmit">确定</el-button>
                <el-button  type="primary" style="float: right; margin-top: 16px" size="small" @click="handleCancel">取消</el-button>
            </div>
           
        </el-dialog>
    </div>
</template>
<script>
import { Button, Tree, Dialog } from 'element-ui';
import store from '../../store/index';
export default {
    name: "choicePersonnel",
    components: {
        'el-button': Button,
        'el-tree': Tree,
        'el-dialog': Dialog,
    },
    data() {
        return {
            title: '选择用户',
            visible: false,
            category:'',
            categoryList:[],
            successDefaultClose: true,
            successCallback: null,
        };
    },
    watch:{
        visible(val){
          if(val)
          {
             console.log('this.category', this.category,typeof this.category );
          }
          else{
            this.category='';
          }
        }
    },
    created(){
        // console.log('created, choicePersonnel');
    },
    mounted(){
        console.log();
        // console.log('mounted, choicePersonnel');
    },
    computed: {
 
    },
    methods:{
        handleSubmit()
        {
            if(typeof this.successCallback === 'function')
            {
                this.successCallback(this.category);
            }
            this.visible=false;

        },
        handleClose()
        {
            this.visible=false;
        },
        handleCancel()
        {
            this.visible=false;
        }
    }
   
   
};
</script>
<style lang="less">
    #choicePersonnel{
        &>.choiceCatogryDialog{
            &>.el-dialog{
                min-width: 200px;
                height: 200px;
                & div{
                    box-sizing: border-box;
                }
                &>.el-dialog__header {
                    padding: 12px 10px !important;
                    background: #058e44;
                    // background-image: linear-gradient(rgb(6, 194, 122), rgb(1, 133, 89));
                    // background-image: linear-gradient(538deg,
                    //         rgb(4, 163, 155),
                    //         rgb(2, 117, 112)) !important;
                    &>.el-dialog__title {
                        font-size: 16px !important;
                        color: white !important;
                        font-weight: bold !important;
                    }
                    &>.el-dialog__headerbtn{
                        position: absolute;
                        top: 20px;
                        right: 20px;
                        padding: 0;
                        background: 0 0;
                        border: none;
                        outline: 0;
                        cursor: pointer;
                        font-size: 16px;
                        &>.el-dialog__close {
                            color: white !important;
                            font-size: 21px !important;
                            position: relative !important;
                            top: -6px !important;
                        }
                    }
                    
                }
                &>.el-dialog__body {
                    height: calc( 100% - 48px );
                    display: flex;
                    flex-flow: column nowrap;
                    align-items: center;
                    justify-content: space-between;
                    // &>.strrbox{
                    //     flex-grow: 1;
                    //     overflow: auto;
                    //     display: flex;
                    //     // width: 98% !important;
                    //     height: 100%;
                    //     margin: 30px auto 0;
                    //     // margin-top: 30px;
                    //     &>.titlebox {
                    //         width: 95% !important;
                    //         position: absolute !important;
                    //         top: 57px !important;
                    //         height: 20px !important;

                    //         p:first-child {
                    //             width: 48% !important;
                    //             float: left !important;
                    //             height: 24px !important;
                    //             margin-right: 5% !important;
                    //             font-weight: 600 !important;
                    //             color: #000 !important;
                    //         }

                    //         p:last-child {
                    //             width: 47% !important;
                    //             float: left !important;
                    //             height: 24px !important;
                    //             font-weight: 600 !important;
                    //             color: #000 !important;
                    //             margin-left: -6px !important;
                    //         }
                    //     }
                    //     &>.leftBox {
                    //         // width: 49% !important;
                    //         flex-grow: 1;
                    //         flex-shrink: 0;
                    //         min-width: 500px;
                    //         height: 100% !important;
                    //         overflow: hidden !important;
                    //         overflow-y: auto !important;
                    //         margin-right: 2% !important;
                    //         border: #ccc solid 1px !important;
                    //         &>.el-input{
                    //             margin: 15px 16px 10px;
                    //             width: calc( 100% - 32px );
                    //             &>.el-input__inner{
                    //                 &:focus,
                    //                 &:hover{
                    //                     border: 1px solid #0a8a55;
                    //                 }
                    //             }
                    //         }
                    //         &>.el-tree{
                    //             & .el-tree-node{
                    //                 &.is-current{
                    //                     &>.el-tree-node__content{
                    //                         background: #02b980 !important;
                    //                         color: #fff;
                    //                         &>.my-custom-label{
                    //                             & .highlight {
                    //                                 color: #fff;
                    //                             }
                    //                         }
                    //                     }
                    //                 }
                    //                 &>.el-tree-node__content {
                    //                     &:hover{
                    //                         background: #02b980 !important;
                    //                         color: #fff;
                    //                         &>.my-custom-label{
                    //                             & .highlight {
                    //                                 color: #fff;
                    //                             }
                    //                         }
                    //                     }
                    //                     &>.el-tree-node__expand-icon{
                    //                         &.is-leaf{
                    //                             background-color: unset!important;
                    //                         }
                    //                     }
                    //                     &>.my-custom-label{
                    //                         & .highlight {
                    //                             color: #02b980;
                    //                         }
                    //                     }
                    //                 }
                                    
                    //             }
                    //         }
                    //     }
                    //     &>.rightBox {
                    //         // width: 49% !important;
                    //         // flex-grow: 1;
                    //         width: 600px;
                    //         height: 100% !important;
                    //         overflow: hidden;
                    //         border: #ccc solid 1px !important;
                    //         padding: 15px 16px 20px;
                    //         display: flex;
                    //         flex-flow: column nowrap;
                    //         align-items: stretch;
                    //         &>.el-input{
                    //             flex-shrink: 0;
                    //             &>.el-input__inner{
                    //                 &:focus,
                    //                 &:hover{
                    //                     border: 1px solid #0a8a55;
                    //                 }
                    //             }
                    //         }
                    //         &>.el-table{
                    //             margin: 10px 0;
                    //             flex-grow: 1;
                    //             overflow: auto;
                    //             & .cell{
                    //                 &.selection:not(.multiple){
                    //                     display: none;
                    //                 }
                    //             }
                    //         }
                    //         &>.el-pagiantion{
                    //             flex-shrink: 0;
                    //         }
                    //     }
                    // }
                    &>.buttons{
                        width: 100%;
                        display: flex;
                        justify-content: center;
                        &>.el-button{
                        // margin-right:1%;
                        // align-self: flex-end;
                        background-color: #05623b;
                        border-color: #05623b;
                        color: #fff;
                        // margin-left: 30px;
                    }
                    }
                   
                }   
            }
        }
    }
</style>