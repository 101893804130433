import Vue from "vue";
import Vuex from "vuex";
Vue.use(Vuex);
import api from "../api/api";

export default new Vuex.Store({
    state: {
        token: '',
        userInfo: {
            id: null,
            nickname:'',
            sex:0,
            avatar:'',
        },
        seletePrefix:localStorage.getItem('seletePrefix') || '',
    },
    mutations: {
        setToken: (state, token) => {
            state.token = token;
        },
        setUserInfo: (state, userInfo) => {
            // console.log('mutations.setUserInfo', userInfo);
            state.userInfo = userInfo;
        },
        setPrefix: (state,seletePrefix)=>
        {
            state.seletePrefix = seletePrefix;
            
            localStorage.setItem('seletePrefix',seletePrefix);
        },
        setNickName(state,nickname)
        {
          state.userInfo.nickname = nickname;
        },
        setSex(state,sex)
        {
          state.userInfo.sex = sex;
        },
        setAvatar(state,avatar)
        {
            state.userInfo.avatar = avatar;
        }

    },
    actions: {
        getUserInfo({ commit }){
            return new Promise((resolve, reject) => {
                api.postFormAPISM(
                    // http://127.0.0.1:2333/api/user/getuserinfo
                    '',
                    '/user/getuserinfo'
                )
                .then(res => {
                    // console.log('actions.getUserInfo', res.data.data.user);
                    let { fans_count,send_count, follow_count, user } = res.data.data;
                    let userInfo = {
                        fans_count: fans_count || 0,
                        follow_count: follow_count || 0,
                        ...user,
                         send_count:send_count || 0
                    }
                    // console.log('actions, getUserInfo, then', userInfo);
                    commit('setUserInfo', userInfo);
                    resolve(userInfo);
                }).catch(error => {
                    console.log('error', error);
                    reject(false);
                })
            })
            
        },
        // 移动端修改昵称
       async updateNickName({commit},nickname)
        {
            const {avatar,sex} = this.state.userInfo
            try {
              await api.
               postFormAPISM(
                   {
                    nickname,
                    avatar,
                    sex
                   },
                   "/user/edituserinfo"
               ); 
               commit('setNickName',nickname);
            } catch (error) {
                 throw new Error(error);
            }
        },
         // 移动端修改性别
       async updateSex({commit},sex)
       {
           const {avatar,nickname} = this.state.userInfo
           try {
            await api.
              postFormAPISM(
                  {
                   nickname,
                   avatar,
                   sex
                  },
                  "/user/edituserinfo"
              ); 
              commit('setSex',sex);
           } catch (error) {
                throw new Error(error);
           }
       },
         // 移动端修改头像
         async updateAvater({commit},avatar)
         {
             const {sex,nickname} = this.state.userInfo
             try {
               await api.
                postFormAPISM(
                    {
                     nickname,
                     avatar,
                     sex
                    },
                    "/user/edituserinfo"
                ); 
                commit('setAvatar',avatar);
             } catch (error) {
                  throw new Error(error);
             }
         }

    },
    modules: {},
});
