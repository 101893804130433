import http from '../utils/http'

// let msIp = "http://192.168.23.15:8000";
let msIp = "/api";
//文档编辑在 http://47.104.68.37:13010/oeapi
// let onlineEditorIp = process.env.NODE_ENV === 'development' ? '/oeapi' : 'http://47.104.68.37:13010/api';
let onlineEditorIp = '/oeapi';
const ImageBasicUrl = 'http://47.104.68.37:13008/static/images/';
let msIp1 = "/sss";
export default class api {

    //==========↓==========↓==========↓==========↓==========GET==========↓==========↓==========↓==========↓==========

    /**
     *  @parms resquest 请求地址 例如：http://197.82.15.15:8088/request/...
     *  @param '/testIp'代表vue-cil中config，index.js中配置的代理
     */
    static getListAPIMS(params, url) {
        return http.get(`${msIp}${url}`, params)
    }

    static postFormAPISM(params, url, urlData, config) {
        return http.post(`${msIp}${url}`, params, urlData, config)
    }

    static postFormDataAPISM(params, url, urlData) {
        return http.postFormData(`${msIp}${url}`, params, urlData)
    }

    //文档编辑
    static getListAPIOE(params, url) {
        return http.get(`${onlineEditorIp}${url}`, params)
    }

    static postFormAPIOE(params, url, urlData, headers) {
        return http.post(`${onlineEditorIp}${url}`, params, urlData, headers)
    }
    static postFormDataAPIOE(params, url, urlData) {
        return http.postFormData(`${onlineEditorIp}${url}`, params, urlData)
    }
    //   测试接口
    static FormAPISM(params, url, urlData) {
        return http.post(`${msIp1}${url}`, params, urlData)
    }

}

export {
    ImageBasicUrl,
    onlineEditorIp,
}