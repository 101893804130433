/*
 * @Author: 余生 906524998@qq.com
 * @Date: 2023-02-15 11:10:38
 * @LastEditors: 余生 906524998@qq.com
 * @LastEditTime: 2024-01-16 09:19:22
 * @FilePath: \cooask_frontend\src\utils\http.js
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 */
/****   http.js   ****/
// 导入封装好的axios实例
import request from "./request";
const http = {
  /**
   * methods: 请求
   * @param url 请求地址
   * @param params 请求参数
   */
  get(url, params) {
    const config = {
      method: "get",
      url: url
    };
    if (params) config.params = params;
    return request(config);
  },
  post(url, params, urlData,mergeConfig) {
    const config = {
      method: "post",
      url: url,  
      // headers: {
      //   ...(headers || {})
      // },
      ...mergeConfig || {}
    };
    if (params) config.params = urlData;
    config.data = params;
    return request(config);
  },
  postFormData(url, params, urlData) {
    const config = {
      method: "post",
      url: url,
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    };
    if (params) config.params = urlData;
    config.data = params;
    return request(config);
  },
  put(url, params) {
    const config = {
      method: "put",
      url: url
    };
    if (params) config.params = params;
    return request(config);
  },
  delete(url, params) {
    const config = {
      method: "delete",
      url: url
    };
    if (params) config.params = params;
    return request(config);
  }
};
//导出
export default http;
