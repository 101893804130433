//login.js文件
import Vue from 'vue'
import Login from './Login.vue';
 
const $app = Vue.extend(Login);
 
const $login = new $app().$mount(document.createElement('div'))
 
 
document.body.appendChild($login.$el)
 
 
 
export default {
    install(vm) {
        vm.prototype.$login = {
            show: (params) => {
                Object.keys(params).forEach(key => {
                    $login[key] = params[key]
                })
                let { type } = params || {};
                $login.visible = true;
                $login.active = params && type === 'register' ? 'verify_code' : 'account_password';
                
            },
            hide: () => {
                $login.visible = false
            }
        }
    }
}