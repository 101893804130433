/*
 * @Author: 余生 906524998@qq.com
 * @Date: 2023-02-15 11:10:38
 * @LastEditors: 余生 906524998@qq.com
 * @LastEditTime: 2024-01-19 15:28:08
 * @FilePath: \cooask_frontend\src\router\index.js
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 */
import Vue from "vue";
import VueRouter from "vue-router";
import store from '../store/index';
// 获取原型对象push函数
const originalPush = VueRouter.prototype.push
// 获取原型对象replace函数
const originalReplace = VueRouter.prototype.replace
// 修改原型对象中的push函数
VueRouter.prototype.push = function push(location) {
    return originalPush.call(this, location).catch(err => err)
}
// 修改原型对象中的replace函数
VueRouter.prototype.replace = function replace(location) {
    return originalReplace.call(this, location).catch(err => err)
}

Vue.use(VueRouter);

const routes = [
    {
        path: "/",
        name: "BasicLayout",
        // redirect: '/home',
        component: () => import("@/components/Layout/BasicLayout/index"),
        children: [
            {
                path: "/",
                name: "Home",
                component: () => import("@/views/Home/index"),
                meta: { title: "碳问 - 相拥绿水青山" },
            },
            {
                path: "/home",
                redirect: "/",
            },
            {
                path: "/carbonMarket",
                name: "CarbonMarket",
                component: () => import("@/views/carbonMarket/index"),
                meta: { title: "碳行情（大全国市场）" },
            },
            {
                path: "/carbonBazaar",
                name: "carbonBazaar",
                component: () => import("@/views/carbonBazaar/index"),
                meta: { title: "全国碳市场（大宗交易）" },
            },
            {
                path: "/news",
                name: "News",
                component: () => import("@/views/News/index"),
                meta: { title: "详情" },
            },
            {
                path: "/knowledge",
                name: "Knowledge",
                component: () => import("@/views/Knowledge/index"),
                meta: { title: "碳知识" },
            },
            {
                path: "/candlestick",
                name: "Candlestick",
                component: () => import("@/views/Candlestick/index"),
                meta: { title: "行情" },
            },
            {
                path: "/research",
                name: "Research",
                component: () => import("@/views/Research/index"),
                meta: { title: "碳研社" },
            },
            {
                path: "/u/:id",
                name: "U",
                component: () => import("@/views/U/index"),
                meta: { title: "我的" },
            },
            {
                path: "/post",
                name: "Post",
                component: () => import("@/views/Post/index"),
                meta: { title: "碳研社" },
            },
            {
                path: "/setting",
                name: "SettingLayout",
                redirect: '/setting/user',
                component: () => import("@/components/Layout/SettingLayout"),
                // component: () => import('@/views/Setting/User'),
                children: [
                    {
                        path: '/setting/user',
                        name: 'User',
                        component: () => import('@/views/Setting/User'),
                        meta: { title: '个人资料 - 碳问' }
                    },
                    {
                        path: '/setting/password',
                        name: 'Password',
                        component: () => import('@/views/Setting/Password'),
                        meta: { title: '修改密码 - 碳问' }
                    }
                ],
            },
            {
                path: "/tool",
                name: "Tool",
                redirect: '/tool/index',
                component: () => import("@/views/Tool/index"),
                meta: { title: "碳工具" },
                children: [
                    {
                        path: '/tool/index',
                        name: 'Tool',
                        component: () => import('@/views/Tool/Index/index'),
                        meta: { title: "碳工具" },
                    },
                    {
                        path: '/tool/factor',
                        name: 'Factor',
                        component: () => import('@/views/Tool/Factor/index'),
                        meta: {
                            title: '碳排放因子'
                        },
                    }
                ],
            },
            {
                path: "/search",
                name: "Search",
                component: () => import("@/views/search/index"),
                meta: { title: "碳工具搜索" },
            },
            {
                path: "/updateLog",
                name: "updateLog",
                component: () => import("@/views/updateLog/updateLog"),
                meta: { title: "上传" },
            },
            {
                path: "/NewUpdateLog",
                name: "NewUpdateLog",
                component: () => import("@/views/NewUpdateLog/NewUpdateLog"),
                meta: { title: "新的上传" },
            },
        ],
    },
    {
        path: '/produce',
        name: 'ProduceLayout',
        component: () => import("@/components/Layout/ProduceLayout/index"),
        children: [
            {
                path: '/',
                name: 'ProduceHome',
                component: () => import('@/views/Produce/Home/index'),
                meta: { title: '碳问创作者中心' },
            },
            {
                path: "/produce/write",
                name: "Write",
                component: () => import("@/views/Produce/Write/index"),
                meta: { title: "发布长文" },
            },
        ],
    },
    {
        path:'/admin',
        name:'admin',
        redirect:'/newsAudt',
        component: () => import("@/components/Layout/AdminLayout/index"),
        children:[
        
            {
                path: "/newsAudt",
                name: "newsAudt",
                component: () => import("@/views/admin"),
                meta: { title: "新闻审核" ,requireAuth:true},
            },
            {
                path: "/newPowerSystem",
                name: "newPowerSystem",
                component: () => import("@/views/newPowerSystem"),
                meta: { title: "新型电力系统",requireAuth:true },
            },
            {
                path: "/CertiCen",
                name: "CertiCenr",
                component: () => import("@/views/CertificationCenter"),
                meta: { title: "检测认证",requireAuth:true},
            },
            {
                path: "/reasearchAudit",
                name: "reasearchAudit",
                component: () => import("@/views/ReasearchAudit"),
                meta: { title: "碳研社审核",requireAuth:true},
            },
        
            {
                path: "/publish",
                name: "publish",
                component: () => import("@/views/publish/index"),
                meta: { title: "审核文件" ,requireAuth:true},
            },
            {
                path: "/newsDetail",
                name: "newsDetail",
                component: () => import("@/views/AuditNews/index"),
                meta: { title: "详情" },
            },
            {
                path: "/postDetail",
                name: "postDetail",
                component: () => import("@/views/AuditPost/index"),
                meta: { title: "碳研社",requireAuth:true },
            },
        ]
    },
    {
        path: "/sampleReport",
        name: "sampleReport",
        component: () => import("@/views/sampleReport"),
        meta: { title: "简报池" },
    },
];

const routesM = [
    {
        path: "/login",
        name: "login",
        component: () => import("@/views/Mobile/Login/index"),
        meta: { title: "登录" },
    },
    {
        path: "/",
        // name: "BasicLayout",
        // redirect: '/home',
        component: () => import("@/components/Mobile/Layout/index"),
        children: [
            {
                path: "",
                redirect: "/home",
            },
            {
                path: "/home",
                name: "Home",
                component: () => import("@/views/Mobile/Home/index"),
                meta: { title: "碳问 - 相拥绿水青山" },
            },
            {
                path: "/Candlestick",
                name: "Candlestick",
                component: () => import("@/views/Mobile/Candlestick/index"),
                meta: { title: "行情" },
            },
            {
                path: "/research",
                name: "Research",
                component: () => import("@/views/Mobile/Research/index"),
                meta: { title: "碳研社" },
            },
            // {
            //     path: "/knowledge",
            //     name: "Knowledge",
            //     component: () => import("@/views/Mobile/Knowledge/index"),
            //     meta: { title: "碳知识" },
            // },
            {
                path: "/tool",
                name: "Tool",
                component: () => import("@/views/Mobile/Tool/index"),
                meta: { title: "我的" },
            },
        ],
    },
    {
        path: "/news",
        name: "News",
        component: () => import("@/views/Mobile/News/index"),
        meta: { title: "新闻详情" },
    },
    {
        path: "/post",
        name: "Post",
        component: () => import("@/views/Mobile/Post/index"),
        meta: { title: "碳研社详情" },
    },
    {
        path: "/publish",
        name: "publish",
        component: () => import("@/views/Mobile/Publish/index"),
        meta: { title: "发布长文" },
    },
    {
        path: "/repeat",
        name: "repeat",
        component: () => import("@/views/Mobile/Repeat/index"),
        meta: { title: "转发长文" },
    },
    {
        path: "/settings",
        name: "settings",
        component: () => import("@/views/Mobile/settings/index"),
        meta: { title: "设置" },
    },
    {
        path: "/editPassword",
        name: "editPassword",
        component: () => import("@/views/Mobile/EditPassword/index"),
        meta: { title: "修改登录密码" }, 
    },
    {
        path: "/userInfo",
        name: "userInfo",
        component: () => import("@/views/Mobile/UserInfo/index"),
        meta: { title: "我的资料" }, 
    },
    {
        path: "/editUserInfo",
        name: "editUserInfo",
        component: () => import("@/views/Mobile/EditUserInfo/index"),
        meta: { title: "编辑资料" }, 
    },
    {
        path: "/homepage",
        name: "homepage",
        component: () => import("@/views/Mobile/HomePage/index"),
        meta: { title: "我的主页" }, 
    },
    {
        path: "/fans",
        name: "fans",
        component: () => import("@/views/Mobile/Fans/index"),
        meta: { title: "粉丝" }, 
    },
    {
        path: "/follow",
        name: "follow",
        component: () => import("@/views/Mobile/Follow/index"),
        meta: { title: "关注" }, 
    },
    {
        path: "/userRecord",
        name: "userRecord",
        component: () => import("@/views/Mobile/UserRecord/index"),
        meta: { title: "记录" }, 
    },
    {
        path: "/carbonMarket",
        name: "CarbonMarket",
        component: () => import("@/views/Mobile/CarbonMarket/index"),
        meta: { title: "碳行情（大全国市场）" },
    },
    {
        path: "/carbonBazaar",
        name: "CarbonBazaar",
        component: () => import("@/views/Mobile/CarbonBazaar/index"),
        meta: { title: "全国碳市场（大宗交易）" },
    },
    {
        path: "/prefixPhone",
        name: "prefixPhone",
        component: () => import("@/views/Mobile/prefixPhone/index"),
        meta: { title: "手机号列表" },
    },
    
    // {
    //     path: '/onlineEditor',
    //     name: 'OnlineEditor',
    //     component: () => import('@/views/OnlineEditor/index'),
    //     meta: {
    //         title: 'iLabDoc'
    //     }
    // },
    // {
    //     path: '/formulaEditor',
    //     name: 'FormulaEditor',
    //     component: () => import('@/views/FormulaEditor/index'),
    //     meta: {
    //         title: '公式编辑'
    //     }
    // }
    // { path: '/', redirect: '/home' },
];

const ISMOBILE = function () {
    let flag = navigator.userAgent.match(
        /(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i
    );
    return flag;
};

const router = new VueRouter({
    mode: "history",
    routes: ISMOBILE() ? routesM : routes,
    base: process.env.NODE_ENV === "development" ? "" : "coo",
});


let firstLoad = true;
router.beforeEach((to, from, next) => {
    if(firstLoad){
        if (localStorage.getItem("store")) {
            store.replaceState(
                Object.assign(
                    {},
                    store.state,
                    JSON.parse(localStorage.getItem("store"))
                )
            );
        }
        firstLoad = false;
    }
    console.log("beforeEach", to, store.state.userInfo);
    document.title = (to.query && to.query.title) || (to.meta && to.meta.title);
  console.log(222,);

//    this.$store.state.token

   if( typeof to.meta.requireAuth === 'boolean' && !localStorage.getItem('token') )
   {
    Vue.prototype.$login.show({
        redirect:to.path,
        submitCallback: () => {
            console.log('submitCallback',);
            // window.location.reload();
            next({
                path: to.path,
                replace:true,
                // query:{
                //     'a':''
                // }
            });
        }
    })
   }else if(typeof to.meta.requireAuth === 'boolean' && store.state.userInfo.username == 'houwenzhao' && to.path !=='/CertiCen')
   {
    console.log('CertiCen');
    next('/CertiCen');
   }
   else{
    next();
   }
});

export default router;
