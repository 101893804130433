<template>
    <div :id="isMobile ? 'app-mobile' : 'app'">
        <keep-alive>
            <router-view></router-view>
        </keep-alive>
    </div>
</template>

<script>
// 0118test
// 0118 xxxx
export default {
    name: "app",
    components: {
    },
    data() {
        return {
            isMobile: false,
        };
    },
    created() {
        
        window.addEventListener("beforeunload", () => {
            localStorage.setItem("store", JSON.stringify(this.$store.state));
        });
        console.log("created, app");
        this.initToken();
        this.isApp();

    },
    mounted() {
    },
    methods: {
        isApp() {
            let flag = navigator.userAgent.match(
                /(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i
            );
            // console.log('flag', flag);
            this.isMobile = !!flag;
        },
        initToken() {
            let token = localStorage.getItem('token');
            if (token) {
                this.$store.commit('setToken', token);
                this.$store.dispatch('getUserInfo');
            }
        }
    },
};
</script>

<style>
html {
    height: 100%;
}

#app {
    position: relative;
    /* font-family: "Microsoft YaHei"; */
    width: 100%;
    height: 100%;
    min-width: 1200px;
}

#app-mobile {
    /* height: 100vh; 
     overflow: hidden; */
}



h1,
h2 {
    text-align: center;
}

a {
    text-decoration: none;
    color: #333;
}

div,
span {
    box-sizing: border-box;
}

body {
    width: 100%;
    height: 100%;
    font-family: "Microsoft YaHei, 微软雅黑, sans-serif" !important;
    color: rgb(51, 53, 60);
}

body,
div,
section,
ul,
li,
a,
aside,
main,
p {
    margin: 0;
    padding: 0;
}

/** 清除内外边距 **/
body,
h1,
h2,
h3,
h4,
h5,
h6,
hr,
p,
blockquote,
/* structural elements 结构元素 */
dl,
dt,
dd,
ul,
ol,
li,
/* list elements 列表元素 */
pre,
/* text formatting elements 文本格式元素 */
form,
fieldset,
legend,
button,
input,
textarea,
/* form elements 表单元素 */
th,
td

/* table elements 表格元素 */
    {
    margin: 0;
    padding: 0;
}

h1,
h2,
h3,
h4,
h5,
h6 {
    font-size: 100%;
    font-weight: normal;
}

address,
cite,
dfn,
em,
var {
    font-style: normal;
}

/* 将斜体扶正 */
code,
kbd,
pre,
samp {
    font-family: courier new, courier, monospace;
}

/* 统一等宽字体 */
small {
    font-size: 12px;
}

/* 小于 12px 的中文很难阅读, 让 small 正常化 */
/** 重置列表元素 **/
ul,
ol {
    list-style: none;
}

/** 重置文本格式元素 **/
a {
    text-decoration: none;
}

a:hover {
    text-decoration: underline;
}

sup {
    vertical-align: text-top;
}

/* 重置, 减少对行高的影响 */
sub {
    vertical-align: text-bottom;
}

/** 重置表单元素 **/
legend {
    color: #000;
}

/* for ie6 */
fieldset,
img {
    border: 0;
}

/* img 搭车：让链接里的 img 无边框 */
button,
input,
select,
textarea {
    font-size: 100%;
}

/* 使得表单元素在 ie 下能继承字体大小 */
/* 注：optgroup 无法扶正 */
/** 重置表格元素 **/
table {
    border-collapse: collapse;
    border-spacing: 0;
}

/* 重置 HTML5 元素 */
article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section,
summary,
time,
mark,
audio,
video {
    display: block;
    margin: 0;
    padding: 0;
}

mark {
    background: #ff0;
}

/* 设置placeholder的默认样式 */
:-moz-placeholder {
    color: #ddd;
    opacity: 1;
}

::-moz-placeholder {
    color: #ddd;
    opacity: 1;
}

input:-ms-input-placeholder {
    color: #ddd;
    opacity: 1;
}

input::-webkit-input-placeholder {
    color: #ddd;
    opacity: 1;
}
</style>
