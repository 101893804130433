<template>
  <el-dialog
    title="提示"
    class="my-custom-dialog login-dialog"
    :visible.sync="visible"
    width="450px"
    :show-close="false"
    :append-to-body="true"
    :lock-scroll="false"
    :close-on-click-modal="false"
    :close-on-press-escape="false"
    @close="handleClose('')"
  >
    <div class="my-custom-header">
      <!-- <div class="icon">
                <img src="../../assets/images/u75.png" >
            </div> -->
      <!-- <div class="title">
                碳问
            </div> -->
      <div
        :class="['close-icon', mouseActionClass]"
        @click="handleClose('')"
        @mouseenter="handleCloseMouseEnter"
        @mouseleave="handleCloseMouseLeave"
      >
        <loginClose />
      </div>
    </div>
    <div class="my-custom-body">
      <div class="login-tabs">
        <div
          :class="['login-tabs-item', { active: active === 'verify_code' }]"
          @click="handleChangeLogin('verify_code')"
        >
          <span>验证码登录/注册</span>
        </div>
        <div
          :class="[
            'login-tabs-item',
            { active: active === 'account_password' },
          ]"
          @click="handleChangeLogin('account_password')"
        >
          <span>账号密码登录</span>
        </div>
      </div>
      <div class="login-form">
        <template v-if="active === 'verify_code'">
          <el-input
            v-model="formData.phone"
            placeholder="请输入手机号"
            @blur="handleValidate"
            @input="handleInputOrChange"
          >
            <template #prepend>
              <div class="zone-select">
                <el-select
                  v-model="formData.zone"
                  popper-class="login-zone-select"
                >
                  <el-option label="中国" value="中国"></el-option>
                  <el-option label="韩国" value="韩国"></el-option>
                </el-select>
              </div>
            </template>
            <template #suffix>
              <div class="send-code">发送验证码</div>
            </template>
          </el-input>
          <el-input
            v-model="formData.verifyCode"
            placeholder="请输入验证码"
            @blur="handleValidate"
            @input="handleInputOrChange"
          ></el-input>
        </template>
        <template v-if="active === 'account_password'">
          <el-input
            v-model="formData.username"
            placeholder="请输入手机号或用户名"
            @blur="handleValidate"
            @input="handleInputOrChange"
          >
          </el-input>
          <el-input
            type="password"
            v-model="formData.password"
            placeholder="请输入登录密码"
            @blur="handleValidate"
            @input="handleInputOrChange"
          >
            <template #suffix>
              <div class="forget-password">忘记密码</div>
            </template>
          </el-input>
        </template>
        <div class="option">
          <el-checkbox v-model="formData.autoLogin">下次自动登录</el-checkbox>
        </div>
        <div class="login-btn">
          <el-button @click="handleLogin" :disabled="!valid">登录</el-button>
        </div>
        <div class="protocol-policy">
          注册/登录 即代表同意 <span>碳问服务协议</span> &
          <span>碳问隐私政策</span>
        </div>
      </div>
    </div>
  </el-dialog>
</template>
<script>
import { Dialog, Checkbox, Button } from "element-ui";
import { loginClose } from "../../core/icon";
import store from "../../store/index";
import router from "../../router";
export default {
  name: "Login",
  props: {
    // loginType: {
    //     type: String,
    //     default: 'account_password'
    // },
    // dialogVisible: {
    //     type: Boolean,
    //     default: false
    // }
  },
  components: {
    "el-dialog": Dialog,
    "el-checkbox": Checkbox,
    "el-button": Button,
    loginClose,
  },
  data() {
    return {
      mouseActionClass: "",
      // account_password  verify_code
      active: "account_password",
      mainActive: "",
      visible: false,
      submitCallback: null,
      cancelCallback: null,
      formData: {
        phone: "",
        zone: "中国",
        verifyCode: "",
        username: "",
        password: "",
        disabled: true,
      },
      valid: false,
      validateTimeout: null,
      autoLogin: false,
      redirect: "",
    };
  },
  created() {
    // console.log('created, login');
  },
  activated() {
    // console.log('activated, login');
  },
  computed: {
    // active: {
    //     set: function(){
    //     },
    //     get: function(){
    //         return this.mainActive || this.loginType;
    //     }
    // },
    //     // return this.loginType;
    // visible: {
    //     set: function(){
    //     },
    //     get: function(){
    //         return this.dialogVisible;
    //     }
    // }
  },
  watch: {
    visible:{
        handler(){
            if(this.visible){
                console.log('visible', this.visible, this.submitCallback);
            }
        }
    }
  },
  methods: {
    handleOpenLoginDialog({ type, submitCallback, cancelCallback }) {
      this.active = type === "register" ? "verify_code" : "account_password";
      this.visible = true;
      typeof submitCallback === "function" &&
        (this.submitCallback = submitCallback);
      typeof cancelCallback === "function" &&
        (this.cancelCallback = cancelCallback);
    },
    handleChangeLogin(active) {
      // console.log('handleChangeLogin', active);
      if (active !== this.active) {
        this.active = active;
        this.handleValidate();
      }
    },
    handleInputOrChange() {
      // console.log('handleInputOrChange');
      this.validateTimeout && window.clearTimeout(this.validateTimeout);
      window.setTimeout(() => {
        this.handleValidate();
      }, 500);
    },
    handleValidate() {
      // console.log('handleValidate');
      if (this.active === "account_password") {
        this.valid = this.formData.username && this.formData.password;
      } else {
        this.valid = this.formData.phone && this.formData.verifyCode;
      }
    },
    handleLogin() {
      // console.log('login');
      this.handleValidate();
      if (!this.valid) {
        return this.$message("请补全信息");
      }
      if (this.active !== "account_password") {
        return this.$message("暂不支持验证码登录");
      }
      //   /api/user/login
      this.api
        .postFormAPISM(
          this.active === "account_password"
            ? {
                username: this.formData.username,
                password: this.formData.password,
              }
            : {},
          "/user/login"
        )
        .then(async res => {
          if (
            res.data &&
            res.data.hasOwnProperty("data") &&
            res.data.data.hasOwnProperty("token") &&
            res.data.data.success
          ) {
            localStorage.setItem("token", res.data.data.token);
            store.commit("setToken", res.data.data.token);
           await store.dispatch("getUserInfo");

            if (typeof this.submitCallback === "function") {
              console.log("handleClose. login, innner", "submitCallback");
              this.submitCallback();
            }
            this.handleClose('');
            //     if(this.redirect)
            //    {

            //     router.push(this.redirec)
            //    }
          } else {
            try {
              // console.log('success: false', res.data.data);
              this.$message({
                type: "error",
                message:
                  res.data.data.msg || res.data.data.message || "密码错误",
                // duration: 0,
              });
            } catch (error) {
              // console.log('catch, error', error);
              this.$message({
                type: "error",
                message: "密码错误",
                // duration: 0,
              });
            }
          }
        })
        .catch(error => {
          console.log("error", error);
        });
    },
    handleClose(confirm) {
      console.log("handleClose. login, innner", confirm);
      // this.$emit('handleClose');

      if (!confirm && typeof this.cancelCallback === "function") {
        this.cancelCallback();
      }
      this.mouseActionClass = "";
      this.active = "account_password";
      this.visible = false;
      this.formData = {
        ...this.$options.data().formData,
      };
    },
    handleCloseMouseEnter() {
      // console.log('handleCloseMouseEnter');
      this.mouseActionClass = "active";
    },
    handleCloseMouseLeave() {
      // console.log('handleCloseMouseLeave');
      this.mouseActionClass = "";
    },
  },
};
</script>
<style lang="less">
.my-custom-dialog.login-dialog {
  & > .el-dialog {
    & .login-tabs {
      display: flex;
      justify-content: flex-start;
      border-bottom: 1px solid rgb(217, 217, 217);
      & > .login-tabs-item {
        font-size: 15px;
        margin: 0 14px 0 0;
        padding: 0 4px 2px;
        border-bottom: 1px solid #fff;
        color: #909499;
        cursor: pointer;
        &.active {
          border-bottom: 3px solid #058e44;
          color: #33353c;
        }
      }
    }
    & .login-form {
      padding: 22px 0 0;
      & > .el-input {
        & > .el-input__inner {
          border-radius: 4px;
          &::placeholder {
            font-size: 15px;
            color: #909499;
          }
          &:focus {
            border-color: #dcdfe6;
          }
        }

        & + .el-input {
          margin: 22px 0 0;
        }
        & > .el-input__suffix {
          & > .el-input__suffix-inner {
            display: inline-block;
            height: 100%;
            display: flex;
            align-items: center;
            & > .forget-password {
              height: 26px;
              line-height: 26px;
              padding: 0 14px;
              margin: 0 8px;
              border-radius: 15px;
              background-color: #f6f7fa;
              font-size: 13px;
              color: #909499;
              cursor: pointer;
            }
            & > .send-code {
              color: #909499;
              font-size: 13px;
              padding: 2px 8px;
              border-left: 1px solid rgb(217, 217, 217);
              cursor: pointer;
            }
          }
        }
        & > .el-input-group__prepend {
          background-color: transparent !important;
          & + .el-input__inner {
            border-top-left-radius: 0;
            border-bottom-left-radius: 0;
            border-left-width: 0;
          }
          & > .zone-select {
            // width: 80px;
            & > .el-select {
              width: 80px;
              // height: 38px;
              & > .el-input {
                & .el-input__inner {
                  font-size: 12px;
                  color: #33353c;
                  padding-right: 0;
                }
              }
            }
          }
        }
      }
      & > .option {
        margin: 16px 0 16px;
        & > .el-checkbox {
          & > .el-checkbox__input {
            & > .el-checkbox__inner {
              &:hover {
                border-color: #058e44;
              }
            }
            &.is-checked {
              & > .el-checkbox__inner {
                border-color: #058e44;
                background-color: #058e44;
              }
            }
            &.is-focus {
              & > .el-checkbox__inner {
                border-color: #058e44;
              }
            }
          }
          & > .el-checkbox__label {
            color: #909499;
          }
        }
      }
      & > .login-btn {
        margin: 30px 0 0;
        // padding
        height: 37px;
        & > .el-button {
          width: 100%;
          height: 100%;
          padding: 0;
          background: linear-gradient(
            to right,
            rgba(5, 142, 68, 0.7) 0%,
            rgba(5, 142, 68, 1) 100%
          );
          color: #fff;
          border-radius: 50px;
          font-size: 15px;
          border: unset !important;
        }
      }
      & > .protocol-policy {
        font-size: 13px;
        color: #909499;
        margin: 8px 0 20px;
        & > span {
          color: #058e44;
          cursor: pointer;
        }
      }
    }
  }
}
.login-zone-select {
  & .el-select-dropdown__list {
    & > .el-select-dropdown__item {
      text-align: center;
      font-size: 12px;
      color: #33353c;
      &.selected,
      &:hover {
        color: #058e44;
      }
    }
  }
  & .popper__arrow {
    display: none;
  }
}
</style>