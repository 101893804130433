//choiceCatogry.js文件
import Vue from 'vue'
import ChoiceCatogry from './index.vue';
 
const $app = Vue.extend(ChoiceCatogry);
 
const $choiceCatogry = new $app().$mount(document.createElement('div'))
 
 
document.body.appendChild($choiceCatogry.$el)
 
 
 
export default {
    install(vm) {
        vm.prototype.$choiceCatogry = {
            show: (params) => {
                Object.keys(params).forEach(key => {
                    $choiceCatogry[key] = params[key]
                });
                $choiceCatogry.visible = true;
            },
            hide: () => {
                $choiceCatogry.visible = false
            },
        }
    }
}