/**
 * Custom icon list
 * All icons are loaded here for easy management
 * @see https://vue.ant.design/components/icon/#Custom-Font-Icon
 *
 * 自定义图标加载表
 * 所有图标均从这里加载，方便管理
 */

import edit from '../assets/images/edit.svg?inline';
import faArrowDown from '../assets/images/fa_arrow_down.svg?inline';
import faDownload from '../assets/images/fa_download.svg?inline';
import arrowDown from '../assets/images/arrow-down-filling.svg?inline';
import loginClose from '../assets/images/login-close.svg?inline';
import follow from '../assets/images/follow.svg?inline';
import like from '../assets/images/like.svg?inline';
import likeActive from '../assets/images/like-active.svg?inline';
import likesActive from '../assets/images/mobile/likes-active.svg?inline';
import collect from '../assets/images/collect.svg?inline';
import collectActive from '../assets/images/collect-active.svg?inline';
import collectsActive from '../assets/images/mobile/collects-active.svg?inline';
import alert from '../assets/images/alert.svg?inline';
import userEdit from '../assets/images/user-edit.svg?inline';
import logout from '../assets/images/logout.svg?inline';
import emote from '../assets/images/emote.svg?inline';
import uploadImage from '../assets/images/upload-image.svg?inline';
import commentEditorTriangle from '../assets/images/comment-editor-triangle.svg?inline';
import reply from '../assets/images/reply.svg?inline';
import replysActive from '../assets/images/mobile/replys-active.svg?inline';
import postRepeat from '../assets/images/post-repeat.svg?inline';
import setting from '../assets/images/setting.svg?inline';
import deleteIcon from '../assets/images/delete.svg?inline';

import add from '../assets/images/add.svg?inline';
import checkFollow from '../assets/images/check-follow.svg?inline';

import formulaSave from '../assets/images/formula_save.svg?inline';

import mobileEmail from '../assets/images/mobile/email.svg?inline';
import draft from '../assets/images/mobile/draft.svg?inline';

//碳工具
// 碳排放因子
import carbonFactor from '../assets/images/tool/carbon-factor.svg?inline';
// 工业产品
import industry from '@/assets/images/tool/industry.svg?inline';
// 能源产品
import energy from '@/assets/images/tool/energy.svg?inline';
// 生活产品
import household from '@/assets/images/tool/household.svg?inline';
// 废弃物处理
import waste from '@/assets/images/tool/waste.svg?inline';
// 交通服务
import transport from '@/assets/images/tool/transport.svg?inline';
// 碳汇
import tree from '@/assets/images/tool/tree.svg?inline';


export {
    edit,
    faArrowDown,
    faDownload,
    arrowDown,
    formulaSave,

    loginClose,
    follow,
    like,
    likeActive,
    likesActive,
    collect,
    collectActive,
    collectsActive,
    alert,
    userEdit,
    logout,
    emote,
    uploadImage,
    commentEditorTriangle,
    reply,
    postRepeat,
    setting,
    deleteIcon,
    draft,
    add,
    checkFollow,
    replysActive,
//quickTool
    // 二元运算符Binary operations
    
    // 二元关系符 Binary relations
    // symbol_39_relation_less,
    // symbol_40_relation_more,

    mobileEmail,

    // /tool
    //碳排放因子图标
    carbonFactor,
    industry,
    energy,
    household,
    waste,
    transport,
    tree,
}