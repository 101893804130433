/****   request.js   ****/
// 导入axios
import axios from 'axios'
// 使用element-ui Message做消息提醒
import { MessageBox, Message } from 'element-ui';
const showMessage = Symbol('showMessage')
import store from '../store/index';
// 重写
class DonMessage {
    success(options, single = true) {
        this[showMessage]('success', options, single)
    }
    warning(options, single = true) {
        this[showMessage]('warning', options, single)
    }
    info(options, single = true) {
        this[showMessage]('info', options, single)
    }
    error(options, single = true) {
        this[showMessage]('error', options, single)
    }

    [showMessage](type, options, single) {
        if (single) {
            // 判断是否已存在Message
            if (document.getElementsByClassName('el-message').length === 0) {
                Message[type](options)
            }
        } else {
            Message[type](options)
        }
    }
}
// 使用这个
var MessageOnce = new DonMessage();

//1. 创建新的axios实例，
const service = axios.create({
    baseURL: process.env.BASE_API,
    // 超时时间 单位是ms，这里设置了3s的超时时间
    timeout: 50 * 1000

})
// 2.请求拦截器
service.interceptors.request.use(config => {
  //发请求前做的一些处理，数据转化，配置请求头，设置token,设置loading等，根据需求去添加
  // if (config.headers['Content-Type'] !== 'multipart/form-data'){
  //   config.data = JSON.stringify(config.data); //数据转化,也可以使用qs转换
  // }
  config.headers = {
    'Content-Type': config.headers['Content-Type'] || 'application/json', //配置请求头
    'Accept': "application/json",
    'Authentication': localStorage.getItem("token"),
    // ...(
    //   config.headers ? { responseType: 'blob' } : {}
    // )
  }
  // config.headers && console.log('request.use', config.headers);
  //注意使用token的时候需要引入cookie方法或者用本地localStorage等方法，推荐js-cookie
  const token = sessionStorage.getItem("token");//这里取token之前，你肯定需要先拿到token,存一下
  //  const token = getCookie('名称');//这里取token之前，你肯定需要先拿到token,存一下
  // if (token) {
  //   // config.params = {'token':token} //如果要求携带在参数中
  //   config.headers.token = token; //如果要求携带在请求头中
  // }
  return config
}, error => {
    Promise.reject(error)
})

export function handleConfirm123(text) {
    return MessageBox.alert(text, "提示", {
        confirmButtonText: '确定',
        type: "warning",
        showClose: false,
        confirmButtonClass: "butText1",
    });
}
// 3.响应拦截器
service.interceptors.response.use(response => {
    if (response.data.data === '用户令牌异常') {

    }
    return response
    //接收到响应数据并成功后的一些共有的处理，关闭loading等
}, error => {
    console.log('error', error.response);
    /***** 接收到异常响应的处理开始 *****/
    if (typeof error.response.data === 'object' && (error.response.data.message || error.response.data.msg)) {
        Message.error(error.response.data.message || error.response.data.msg);
    }
    if (error && error.response && error.response.status) {
        // 1.公共错误处理
        // 2.根据响应码具体处理
        switch (error.response.status) {
            case 400:
                MessageOnce.error({ message: "错误请求" });
                break;
            case 401:
                // MessageOnce.error({ message: "未授权，请重新登录" });
                store.commit('setToken','');
                localStorage.removeItem('token','');
                localStorage.removeItem('store','');
                store.commit('setUserInfo',{});
                break;
            case 403:
                MessageOnce.error({ message: "拒绝访问" });
                break;
            case 404:
                MessageOnce.error({ message: "请求错误,未找到该资源" });
                // window.location.href = "/NotFound"
                break;
            case 405:
                MessageOnce.error({ message: "请求方法未允许" });
                break;
            case 408:
                MessageOnce.error({ message: "请求超时" });
                break;
            case 500:
                MessageOnce.error({ message: "服务器端出错" });
                break;
            case 501:
                MessageOnce.error({ message: "网络未实现" });
                break;
            case 502:
                MessageOnce.error({ message: "网络错误" });
                break;
            case 503:
                MessageOnce.error({ message: "服务不可用" });
                break;
            case 504:
                MessageOnce.error({ message: "网络超时" });
                break;
            case 505:
                MessageOnce.error({ message: "http版本不支持该请求" });
                break;
            default:
                MessageOnce.error({ message: `连接错误${error.response.status}` });
                return
        }
    } else if (error && error.response && error.response.message) {
        Message.error(error.response.message)
    } else {
        Message.error(error.message)
    }
    /***** 处理结束 *****/
    //如果不需要错误处理，以上的处理过程都可省略
    throw new Error(error);
    // return Promise.resolve(error.response)
})
//4.导入文件
export default service
